@use "global" as *;

.globalSidebar {
	background: rgba($secondary-color, .3);
	position: fixed;
	top: 0;
	right: 0;
	width: 280px;
	height: 100vh;
	overflow-y: auto;

	&--inner {
		min-height: 100%;
		padding: 30px 20px;
	}

	&--heading {
		font-size: 15px;
		font-weight: bold;
		margin-bottom: 18px;
		line-height: 1.5;

		i {
			font-size: 18px;
			vertical-align: middle;
			margin-right: 3px;
		}

		span {
			border-bottom: 1px dotted;
			padding-bottom: 5px;
		}
	}

	&--section {
		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	&--item {
		font-size: 12px;
		line-height: 1.5;
		display: flex;
		align-items: flex-start;

		&:not(:last-child) {
			margin-bottom: 10px;
		}


		a {
			color: $black;
			display: block;
			background: $white;
			padding: 10px;
			border-radius: 4px;
			border: 1px solid $white;

			&:first-letter {
				color: $primary-color;
				font-weight: bold;
			}

			@include hover {
				border-color: $primary-color;
			}
		}
	}

}