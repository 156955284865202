@use "global" as *;

body.archive {
	main {
		.lead {
			margin-bottom: 40px;
		}

		.chapter,
		.case {
			&--list {}

			&--item {
				font-size: 13px;
				margin-bottom: 10px;
				line-height: 1.5;

				a {
					display: block;
					color: $black;
					background: $light-green;
					padding: 10px;
					padding-right: 30px;
					border: 1px solid $white;
					border-radius: 4px;
					position: relative;

					&::after {
						font-family: FontAwesome;
						content: "\f105";
						position: absolute;
						right: 10px;
						top: 50%;
						transform: translateY(-0.5em);
						line-height: 1;
						color: $primary-color;
					}


					@include hover {
						border: 1px solid $primary-color;
					}
				}

				span {
					color: $primary-color;
					margin-right: 10px;
					font-style: italic;
				}
			}
		}

		.chapter {
			margin-bottom: 40px;
		}

		.case {
			&--title {
				font-size: 18px;
				font-weight: 500;
				margin-bottom: 10px;

				span {
					border-bottom: 1px dotted;
					padding-bottom: 5px;
				}
			}

			&--item {
				a {
					background: $light-beige;
				}
			}
		}
	}

}