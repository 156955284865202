@use "global" as *;

body.single-pharmacy {
	.sectionTitle {
		font-family: $body-font-family-serif;
		font-size: 26px;
		color: $primary-color;
		margin-bottom: 50px;
		font-weight: 500;
		display: none;
		border-bottom: 1px dotted $primary-color;
		padding-bottom: 20px;

		@include breakpoint(large-up) {
			display: block;
		}
	}

	.tags {
		margin-bottom: 70px;

		@include breakpoint(large-up) {
			margin-bottom: 0;
		}

	}

	.sectionHeading {
		font-family: $body-font-family-serif;
		color: $primary-color;
		position: relative;
		border-bottom: 1px solid $medium-gray;
		padding-bottom: 20px;
		margin-bottom: 50px;
		font-size: 20px;

		&::after {
			content: "";
			display: block;
			width: 30%;
			height: 1px;
			position: absolute;
			background: $primary-color;
			bottom: -1px;
		}

		@include breakpoint(medium-up) {
			font-size: 20px !important;
		}

	}
}


body.post-type-archive-pharmacy {
	.sectionTitle {
		font-family: $body-font-family-serif;
		font-size: 14px;
		color: $primary-color;
		margin-bottom: 20px;
		font-weight: 500;

		@include breakpoint(medium-up) {
			margin-bottom: 50px;
			font-size: 15px;
		}


		&::before {
			content: "-";
			margin-right: 6px;
		}
	}
}

body.post-type-archive-pharmacy,
body.single-pharmacy {
	.article {
		background: rgba($primary-color, .05);

		&--inner {
			>section {
				&:not(:last-child) {
					margin-bottom: 70px;
				}
			}
		}
	}

	.pageHeader {
		&.small {
			.pageTitle {
				font-size: 22px;
				font-family: $body-font-family-serif;
				color: $primary-color;
				letter-spacing: .15em;
				font-weight: 500;
				line-height: 1.5;
				margin-bottom: 40px;

				@include breakpoint(medium-up) {
					font-size: 26px;
				}
			}

		}
	}




	.pharmacy {
		margin-bottom: 70px;

		@include breakpoint(large-up) {
			display: flex;
		}




		&--item {
			&:not(:last-child) {
				margin-bottom: 20px;
			}

			a {
				display: block;
				background: $white;
				padding: 20px;
				color: $black;

				@include breakpoint(medium-up) {
					padding: 30px;
				}

				@include hover {
					background: rgba($secondary-color, .1);
				}

			}
		}

		&--name {
			color: $primary-color;
			margin-bottom: 6px;
			font-weight: 500;

			@include breakpoint(medium-up) {
				font-size: 15px;
			}

		}

		&--information {
			margin-bottom: 15px;

			i {
				color: $medium-gray;
				margin-right: 6px;
			}

			span {
				display: block;
			}
		}

		&--right,
		&--left {
			@include breakpoint(large-up) {
				width: 50%;
			}
		}

		&--right {
			@include breakpoint(large-up) {
				order: 2;

			}
		}

		&--left {
			@include breakpoint(large-up) {
				padding-right: 40px;
			}

		}


		&--table {
			border-collapse: collapse;
			width: 100%;
			margin-bottom: 40px;

			th,
			td {
				text-align: left;
				padding: 15px 0;

			}

			tr {
				border-bottom: 1px dotted $primary-color;
			}

			th {
				width: 100px;
				vertical-align: text-top;
				font-weight: normal;
				font-size: 13px;
				color: $dark-gray;
				margin-bottom: 5px;
				padding-top: 17px;

				i {
					color: $primary-color;
					font-size: 18px;
					vertical-align: -1px;
					margin-right: 3px;
				}
			}

			td {
				margin-bottom: 25px;
			}

		}

		&--image {
			margin: 40px 0;

			@include breakpoint(large-up) {
				margin: 0;
				margin-bottom: 20px;
			}


			img {
				width: 100%;
			}
		}

		&--map {
			margin: 40px 0;

			@include breakpoint(large-up) {
				margin: 0;
			}


			iframe {
				height: 290px;
			}
		}

		&--message {
			margin-bottom: 70px;
		}
	}

	.tags {
		&--item {
			background: $white;
			border: 1px solid #4C6072;
			color: #4C6072;
			display: inline-block;
			padding: 6px 10px 7px;
			font-size: 11px;
			line-height: 1;
			margin-bottom: 5px;
			border-radius: 4px;


			&:not(:last-child) {
				margin-right: 5px;
			}
		}
	}
}