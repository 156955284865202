@use "global" as *;

body.single-post {

	.articleTitle {
		font-family: $body-font-family-serif;
		font-size: 20px;
		color: $primary-color;
		letter-spacing: .15em;
		font-weight: 500;
		margin-bottom: 30px;

		@include breakpoint(medium-up) {
			font-size: 26px;
			margin-bottom: 0;
		}

		@include breakpoint(large-up) {
			border-bottom: 1px dotted $primary-color;
			padding-bottom: 20px;
			margin-bottom: 50px;
		}


		&.large {
			@include breakpoint(medium-down) {
				display: none;
			}

		}

	}

	.article {
		background: rgba($primary-color, .05);

		&--content {

			ul,
			ol {
				list-style: inherit;
				padding-left: 1.5em;
			}
		}

		>section {
			&:not(:first-child) {
				margin-top: 70px;
			}

			&:not(:last-child) {
				margin-bottom: 70px;
			}

		}

		h2 {
			font-size: 22px;
			font-weight: 500;
			margin-bottom: 20px;
			color: $primary-color;
			background: rgba($primary-color, .2);
			border-left: 5px solid $primary-color;
			padding: 15px;
			line-height: 1.5;
		}

		h3,
		h4 {
			font-weight: 500;
			font-size: 18px;
			margin-block: 30px 15px;
			color: $primary-color;
		}


		table {

			th,
			td {
				border: 1px solid $medium-gray;
				padding: 10px;
				font-size: 13px;
			}
		}

		.includeBox {
			border: 1px solid;
			padding: 15px;
			margin-block: 40px;
			border-radius: 10px;
			position: relative;

			&::before {
				position: absolute;
				font-size: 13px;
				color: $white;
				top: -1px;
				left: -1px;
				padding: 5px 8px;
				border-radius: 10px 0 10px 0;
			}

			@include breakpoint(medium-up) {
				padding: 50px;
			}

			&.ex {
				border-color: $ex-color;
				background: rgba($ex-color, .1);

				&::before {
					background-color: $ex-color;
					content: "Apprendre à partir d'exemples réels";
				}

				h2,
				h3 {
					color: $ex-color !important;
				}
			}

			&.tips {
				border-color: $tips-color;
				background: rgba($tips-color, .1);

				&::before {
					background-color: $tips-color;
					content: "Astuce pour la pratique";
				}

				h2,
				h3 {
					color: $tips-color !important;
				}
			}

			&.knowledge {
				border-color: $knowledge-color;
				background: rgba($knowledge-color, .1);

				&::before {
					background-color: $knowledge-color;
					content: "Petits Savoirs";
				}


				h2,
				h3 {
					color: $knowledge-color !important;
				}
			}




			&--title {
				color: $black;
				background: none;
				font-size: 18px;
				border-left: none;
				padding: 0;
				border-bottom: 1px dotted;
				padding-bottom: 10px;
				margin-bottom: 20px;
				line-height: 1.5;
			}

			h3 {
				font-size: 16px;
			}
		}
	}

	.lead {
		margin-bottom: 40px;
	}

	.index {
		background: $light-beige;
		list-style: decimal;
		padding: 30px;
		margin-bottom: 40px;

		&::before {
			content: "Sommaire";
			text-align: center;
			display: block;
			margin-bottom: 15px;
			border-bottom: 1px dotted;
			color: $primary-color;
			font-size: 18px;
		}

		li {
			margin-left: 2em;

		}

		a {
			color: $primary-color;

			@include hover {
				text-decoration: underline;
			}
		}
	}

	table {

		th,
		td {
			background: $white;
			vertical-align: top !important;
		}
	}

	.notes {
		font-size: 12px;
		color: $dark-gray;
	}

	figcaption {
		font-size: 12px;
	}


	.article-buttons {
		list-style: none;
		padding-left: 0;
		display: flex;
		justify-content: space-between;
		max-width: 740px;
		margin: 0 auto;
		margin-top: 70px;

		li {
			width: calc(100% / 3 - 4px);
			text-align: center;

			a {
				border: 1px solid $primary-color;
				color: $primary-color;
				border-radius: 3px;
				display: block;
				font-size: 12px;
				padding: 5px 8px;
				background: $white;

				&:hover {
					background: $primary-color;
					color: $white;
					transition: all .25s;
				}
			}

			&.return {
				a {
					border-color: $dark-gray;
					color: $dark-gray;

					&:hover {
						background: $dark-gray;
						color: $white;
					}
				}
			}
		}

		@include breakpoint(medium-up) {
			li {
				width: calc(100% / 3 - 20px);

				a {
					padding: 12px;
					position: relative;

					&::after,
					&::before {
						font-family: FontAwesome;
						position: absolute;
					}
				}

				&.next a::before {
					content: "\f104";
					left: 10px;
				}

				&.prev a::after {
					content: "\f105";
					right: 10px;
				}
			}
		}
	}


}