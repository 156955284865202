@use "global" as *;

@media screen and (max-width: 782px) {
	#wpadminbar {
		display: none;
	}
}



html,
body {
	font-family: $body-font-family;
	font-size: $global-font-size;
	line-height: $global-line-height;
	letter-spacing: $global-letter-spacing;
	color: $body-font-color;
	background-color: $body-background-color;
	position: relative;
	scroll-behavior: smooth;
	background: none;

	@include breakpoint(medium-down) {
		font-size: $global-font-size-mobile;
	}
}

// bodyフェードイン
body {
	// display: none;
}

// footer fixed
html,
body,
.globalWrap {
	height: 100%;
}

.globalWrap {
	display: flex;
	flex-direction: column;
}

main {
	flex: 1;
}


a {
	text-decoration: none;
	color: $anchor-color;

	@include hover {
		color: lighten($anchor-color, 10%);
		transition: $global-anchor-transition;
	}
}

figure {
	margin: 40px 15px;

	@include breakpoint(medium-up) {
		margin: 50px 30px;
	}

}

img {
	max-width: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

iframe {
	width: 100%;
}

svg {
	background: none;
	fill: currentColor;
}

label {
	cursor: pointer;
}

p {
	margin-bottom: 1em;
}

// 2カラムレイアウト
body:not(.home) {
	main {
		@include breakpoint(large-up) {
			display: grid;
			grid-template-columns: minmax(340px, 30%) 1fr;
			grid-template-areas: "header article"
		}

		.pageHeader {
			grid-area: header;


		}

		.article {
			grid-area: article;
		}

	}
}

body:not(.home) {
	main {
		.article {
			&--inner {
				@include container;
				max-width: $global-width-narrow;
				padding-block: 30px 40px;

				@include breakpoint(large-up) {
					padding-block: 60px;
				}

			}
		}
	}
}




.pageHeader {
	@include container;
	padding-top: 100px;
	overflow: hidden;
	position: relative;


	&.small {

		@include breakpoint(large-up) {
			display: none;
		}

		.pageHeader--inner {
			position: static;
			top: 0;
		}
	}

	&.large {
		background: rgba($secondary-color, .09);

		@include breakpoint(medium-down) {
			display: none;
		}

	}

	.circle {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		height: 500px;
		z-index: -1;

		@include breakpoint(large-up) {
			display: block;
		}

		&-yellow {
			fill: rgba($secondary-color, .07);
			top: 185px;
			left: auto;
			right: 10%;
		}

		&-blue {
			fill: rgba($primary-color, .03);
			height: 200px;
			top: 560px;
			left: 40%;
		}
	}

	&--inner {
		@include breakpoint(large-up) {
			position: fixed;
			top: 200px;

		}

	}
}





.pageTitle {
	font-size: 14px;
	color: $primary-color;
	margin-bottom: 40px;
	line-height: 1.5;
	font-family: $body-font-family-serif;

	@include breakpoint(large-up) {
		margin-bottom: 70px;
	}


	&--english {
		font-size: 22px;
		font-family: $body-font-family-serif;
		color: $primary-color;
		letter-spacing: .15em;
		font-weight: 500;
		line-height: 1.5;
		margin-bottom: 10px;

		@include breakpoint(medium-up) {
			font-size: 26px;
		}


	}
}

.pageIndex {
	&--list {
		@include breakpoint(medium-down) {
			display: none;
		}

	}

	&--item {
		font-family: $body-font-family-serif;

		&:not(:last-child) {
			margin-bottom: 15px;
		}

		&::before {
			content: "-";
			margin-right: 6px;
		}

		a {
			color: $black;

			@include hover {
				color: $secondary-color;
			}
		}
	}
}



.no-scroll {
	touch-action: none;
	-webkit-overflow-scrolling: none;
	overflow: hidden;
	overscroll-behavior: none;
}

.toTop {
	&.is-hidden {
		display: none !important;
	}
}


.js-fadeIn {
	transform: translateY(30px);
	opacity: 0;
	visibility: hidden;
	transition: all 1s;

	&.is-show {
		transform: translateY(0);
		opacity: 1;
		visibility: visible;
	}
}

.box {
	background: $white;
	padding: 20px;
	margin-block: 40px;

	@include breakpoint(medium-up) {
		padding: 40px;
	}

	&--title {
		font-size: 15px;
		border-bottom: 1px solid $secondary-color;
		padding-bottom: 10px;
		margin-bottom: 20px;
	}
}

.breadcrumbs {
	font-size: 12px;
	margin-bottom: 40px;

	@include breakpoint(medium-up) {
		margin-bottom: 70px;
	}



	li {
		display: inline;

		&:not(:last-child) {
			&::after {
				content: "/";
				margin-inline: 6px;
				color: $medium-gray;
			}
		}

		&:last-child {
			color: $medium-gray;
		}
	}
}

.table-normal {
	width: 100%;

	th,
	td {
		border: 1px solid $medium-gray;
		padding: 10px;
		font-size: 13px;
		vertical-align: middle;

		@include breakpoint(medium-up) {
			padding: 20px;
		}

	}

	th {
		font-weight: 500;
		width: 8em;
		text-align: center;
		background: #F3F5F9;

		@include breakpoint(medium-up) {
			width: 10em;
		}

	}

	td {
		@include breakpoint(medium-up) {
			padding: 20px 30px;
		}

	}
}

.buttons {
	max-width: 300px;
	margin-inline: auto;

	@include breakpoint(medium-up) {
		display: flex;
		justify-content: center;
	}

	&--item {
		&:not(:last-child) {
			@include breakpoint(medium-up) {
				margin-right: 15px;
			}

		}
	}

	.button {
		display: flex;
		padding: 0;
		height: 60px;
		justify-content: center;
		align-items: center;
		margin-bottom: 15px;


		img {
			height: 25px;
		}
	}

}

.news {
	padding-top: 7px;

	&--item {
		&:not(:last-child) {
			margin-bottom: 15px;

			@include breakpoint(medium-up) {
				margin-bottom: 20px;
			}
		}

		a {
			color: $black;

			@include hover {
				color: $secondary-color;
			}

			@include breakpoint(large-up) {
				display: flex
			}
		}

		.top {
			margin-bottom: 6px;
			flex-shrink: 0;

			@include breakpoint(large-up) {
				margin-right: 20px;
			}

		}

	}

	&--date {
		font-size: 13px;
		color: $black !important;
	}

	&--category {
		background: $primary-color;
		color: $white;
		font-size: 11px;
		line-height: 1;
		padding: 3px 10px 4px;
		align-self: center;
		margin-left: 13px;

		&.recruit {
			background: $secondary-color;
		}
	}

	&--title {
		line-height: 1.5;
		padding-top: 8px;
	}
}

.list-circle {
	font-family: FontAwesome;
	list-style: "\f1db";
	padding-left: 1em;

	li {
		padding-left: 10px;

		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}

	li::marker {
		color: $primary-color;
	}
}

.reference {
	border: 1px solid $medium-gray;
	padding: 20px;
	border-radius: 4px;

	@include breakpoint(medium-up) {
		padding: 40px;
	}


	&--title {
		border-bottom: 1px dotted $medium-gray;
		padding-bottom: 5px;
		margin-bottom: 10px;
		font-size: 13px;
	}

	&--list {
		font-family: FontAwesome;
		list-style: "\f0da";
		padding-left: 1em;
		font-size: 13px;
	}

	&--item {
		padding-left: 1em;

		&::marker {
			color: $medium-gray;
		}
	}
}