@use "global" as *;

.clearfix::after {
	content: "";
	display: block;
	clear: both;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-bold {
	font-weight: bold;
}

.mt0 {
	margin-top: 0 !important;
}

.mt20 {
	margin-top: 20px !important;
}

.mt50 {
	margin-top: 50px;
}

.mt60 {
	margin-top: 60px;
}


.mb10 {
	margin-bottom: 10px;
}

.mb0 {
	margin-bottom: 0 !important;
}

.mb20 {
	margin-bottom: 20px;
}

.mb25 {
	margin-bottom: 25px;
}

.mb30 {
	margin-bottom: 30px;
}

.mb50 {
	margin-bottom: 50px;
}

.mb60 {
	margin-bottom: 60px;
}

.hide-for-small-only {
	@include breakpoint(small-only) {
		display: none !important;
	}
}

.hide-for-medium {
	@include breakpoint(medium-up) {
		display: none !important;
	}
}

.hide-for-large {
	@include breakpoint(large-up) {
		display: none !important;
	}
}

.hide-for-medium-down {
	@include breakpoint(medium-down) {
		display: none !important;
	}
}