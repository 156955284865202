// Color
$primary-color: #254C9B;
$secondary-color: #F1A92A;
$success-color: #12ABC7;
$alert-color: #EA931C;
$warning-color: #ED671E;

$anchor-color: $secondary-color;

$black: #2a2a2a;
$white: #fff;
$light-gray: #F2F2F2;
$medium-gray: #ddd;
$dark-gray: #6a6a6a;
$light-beige: #F5F4EC;
$light-green: #ECF5EC;
$light-blue: #F3F5F9;

$ex-color: #4B9125; // 実例に学ぶ
$tips-color: $alert-color; // 実践のヒント
$knowledge-color: $success-color; // ひとくち知識

$palette: (
	primary: $primary-color,
	secondary: $secondary-color,
	success: $success-color,
	alert: $alert-color,
	warning: $warning-color,
);


$global-font-size: 14px;
$global-font-size-mobile: 14px;
$global-width: 1360px;
$global-width-narrow: 990px;
$global-line-height: 1.85;
$global-unit: round($global-line-height * $global-font-size) !default;
$global-letter-spacing: .07em;
$global-border-radius: 3px !default;
$global-anchor-transition: all .25s;
$global-container-padding-small: 20px;
$global-container-padding-large: 80px;

$body-font-color: $black;
$body-background-color: $white;
$body-font-family: 'Noto Sans JP', '游ゴシック Medium', '游ゴシック体', 'Yu Gothic Medium', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif;
$body-font-family-serif: 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", serif;