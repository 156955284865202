@use "global" as *;

.globalHeader {
	position: relative;
	background: $white;

	&--inner {}

	&--logo {
		position: absolute;
		top: 15px;
		left: 15px;
		width: 180px;
		z-index: 10;

		@include breakpoint(medium-up) {
			width: 220px;
			left: 30px;
			top: 23px;
		}


		.globalHeader.is-open & {
			position: fixed;
			z-index: 100;
		}
	}
}

body:not(.home) {
	.globalHeader--logo {
		@include breakpoint(large-up) {
			position: fixed;
			top: 60px;
			left: 60px;
		}

	}
}

.headerNav {
	padding-inline: 20px;
	padding-bottom: 50px;
	overflow-y: auto;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	height: 100dvh;
	background: rgba($white, .8);
	backdrop-filter: blur(4px);
	z-index: 90;
	visibility: hidden;
	opacity: 0;
	transition: all .5s;

	@include breakpoint(medium-up) {
		display: flex;
		justify-content: center;
		align-items: center;
	}


	.globalHeader.is-open & {
		visibility: visible;
		opacity: 1;
		transition: all .5s;
	}



	&--inner {
		padding-top: 100px;

		@include breakpoint(medium-up) {
			@include container;
			max-width: $global-width-narrow;
		}

	}

	&--blind {
		visibility: hidden;
		opacity: 0;
		z-index: 95;
		content: "";
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100px;
		transition: all .5s;
		background: linear-gradient(rgba($white, 1)50%, rgba($white, .1));

		.globalHeader.is-open & {
			visibility: visible;
			opacity: 1;
			transition: all .5s;
		}

	}



	&--mainMenu {
		@include breakpoint(medium-up) {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;
		}

		>section {
			margin-bottom: 40px;

			@include breakpoint(medium-up) {
				max-width: 33%;
			}

		}

		h2 {
			font-family: $body-font-family-serif;
			color: $primary-color;
			font-size: 20px;
			border-left: 3px double $primary-color;
			line-height: 1;
			padding-left: 10px;
			margin-bottom: 20px;
			letter-spacing: .2em;
			font-weight: 500;

			@include breakpoint(large-up) {
				font-size: 26px;
				margin-bottom: 30px;
			}

		}
	}

	&--item {
		margin-bottom: 15px;
		padding-left: 13px;

		a {
			display: block;
			color: $black;


			@include hover {
				color: $secondary-color;
			}
		}
	}

	.subMenu {
		&--list {
			display: flex;
			justify-content: space-around;

		}

		&--item {
			width: 48%;
			text-align: center;
			font-size: 13px;
			max-width: 300px;

			a {
				border: 1px solid $primary-color;
				color: $primary-color;
				padding: 8px 10px;
				display: block;

				@include breakpoint(medium-up) {
					padding: 15px;
					font-size: 14px;
				}

			}
		}
	}
}


.navToggle {
	position: fixed;
	top: 10px;
	right: 10px;
	width: 50px;
	height: 50px;
	border: 1px solid $primary-color;
	background: rgba($white, .8);
	color: $primary-color;
	border-radius: 50%;
	font-size: 10px;
	z-index: 100;
	text-align: center;
	backdrop-filter: blur(4px);
	cursor: pointer;

	@include breakpoint(medium-up) {
		width: 80px;
		height: 80px;
		font-size: 14px;
	}

	@include breakpoint(large-up) {
		width: 100px;
		height: 100px;
	}



	>div {
		position: relative;
	}


	span {
		display: block;
		position: absolute;
		height: 1px;
		width: 100%;
		background: $primary-color;
		left: 0;
		-webkit-transition: .35s ease-in-out;
		-moz-transition: .35s ease-in-out;
		transition: .35s ease-in-out;

		&:nth-child(1) {
			top: 24px;
			left: 16px;
			opacity: 0;
			transform: rotate(45deg);
			width: 16px;

			@include breakpoint(medium-up) {
				width: 26px;
				top: 37px;
				left: 26px;
			}

			@include breakpoint(large-up) {
				top: 47px;
				left: 36px;

			}

		}

		&:nth-child(2) {
			top: 24px;
			left: 16px;
			opacity: 0;
			transform: rotate(-45deg);
			width: 16px;

			@include breakpoint(medium-up) {
				width: 26px;
				top: 37px;
				left: 26px;
			}

			@include breakpoint(large-up) {
				top: 47px;
				left: 36px;
			}


		}

		&:nth-child(3) {
			top: 15px;
			background: none;
			color: $primary-color;

			@include breakpoint(medium-up) {
				top: 25px;
			}

			@include breakpoint(large-up) {
				top: 35px;
			}


		}
	}


	.globalHeader.is-open & {
		span {
			&:nth-child(1) {
				opacity: 1;
			}

			&:nth-child(2) {
				opacity: 1;

			}

			&:nth-child(3) {
				opacity: 0;
			}
		}

	}

}