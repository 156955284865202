@use "global" as *;
@use "sass:math";

a.button,
button.button,
input.button {
  display: inline-block;
  background: $primary-color;
  color: $white;
  border: 0;
  cursor: pointer;
  padding: math.div($global-unit, 1.4) $global-unit*3;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  letter-spacing: 1px;
  line-height: 1;
  transition: all .25s;
  text-decoration: none;
  min-width: 260px;

  &:hover {
    color: $white;
    background: darken($primary-color, 7%);
    text-decoration: none;
  }

  &.empty {
    background: transparent;
    color: $white;

    &:hover {
      background: darken($primary-color, 5%);
      color: darken($white, 10%);
    }
  }

  &.border {
    background: $white;
    color: $primary-color;
    border: 1px solid $primary-color;

    &:hover {
      background: $primary-color;
      color: $white;
    }

    &.gray {
      background: $white;
      border: 1px solid $medium-gray;

      &:hover {
        background: $light-gray;
        color: $black;
      }
    }
  }

  &.gray {
    background: $medium-gray;
    color: $black;

    &:hover {
      background: darken($medium-gray, 5%);
    }
  }

  &.radius {
    border-radius: 3px;
  }

  &.round {
    border-radius: 50px;
  }

  &.small {
    padding: math.div($global-unit, 2) math.div($global-unit, 2);
    font-size: 13px;
  }

  &.wide {
    display: block;
    width: 100%;
  }

  &.icon {
    position: relative;

    &:after {
      font-family: 'FontAwesome';
      content: "\f105";
      position: absolute;
      right: 0.8rem;
    }

    .fa {
      margin: 0 $global-unit;
      color: darken($white, 20%);
    }

    &.la {
      &:after {
        font-family: 'Line Awesome Free';
        font-weight: 900;
      }
    }

    &.small {
      padding: math.div($global-unit, 4) $global-unit;

      &:after {
        font-size: 11px;
        right: 6px;
        margin-top: -5px;
      }
    }

    .fa:first-child {
      margin-left: 0;
    }

    .fa:last-child {
      margin-right: 0;
    }
  }

  @include breakpoint(small-only) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@each $cl, $color in $palette {

  a.button.#{$cl},
  button.button.#{$cl},
  input.button.#{$cl} {
    background: $color;
    color: contrast-color($color);

    .fa {
      @if $cl =='black' {
        color: $white;
      }

      @else if $cl =='white' {
        color: $black;
      }

      @else {
        color: darken($color, 20%);
      }
    }

    &:hover {
      @if $cl =='black' {
        background: lighten($black, 10%);
      }

      @else {
        background: darken($color, 7%);
      }
    }

    &.border {
      background: none;
      color: $color;
      border-color: $color;

      &:hover {
        background: $color;
        color: $white;
      }
    }
  }
}