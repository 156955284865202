@use "global" as *;

// search form
.searchForm {
	display: flex;
	max-width: 600px;
	margin-inline: auto;

	&--input {
		width: 100% !important;
		margin-right: 6px;
	}

	&--submit {
		width: 42px;
		display: flex;
		align-items: center;
		justify-content: center;

	}
}