@use "global" as *;

body.page-template {
	.article {
		background: rgba($primary-color, .05);

		&--inner {

			>section,
			.s-intro {
				&:not(:last-child) {
					margin-bottom: 100px;

					@include breakpoint(medium-up) {
						margin-bottom: 140px;
					}

				}
			}

		}
	}

	.sectionTitle {
		font-family: $body-font-family-serif;
		font-size: 16px;
		color: $primary-color;
		margin-bottom: 20px;
		font-weight: 500;
		border-left: 5px solid rgba($primary-color, .2);
		padding-left: 15px;

		@include breakpoint(medium-up) {
			margin-bottom: 50px;
			font-size: 20px;
		}


	}

	.sectionHeading {
		font-family: $body-font-family-serif;
		font-size: 20px;
		color: $black;
		letter-spacing: .15em;
		font-weight: 500;
		margin-bottom: 30px;

		@include breakpoint(medium-up) {
			font-size: 26px;
			margin-bottom: 50px;
		}
	}

	.sectionSubHeading {
		font-size: 16px;
		font-family: $body-font-family-serif;
		color: $primary-color;
		margin-bottom: 20px;
		margin-top: 50px;

		@include breakpoint(medium-up) {
			font-size: 18px;
		}

	}

}



body.page-template-philosophy {
	.s-philosophy {
		.sectionHeading {
			text-align: center;
			background: $white;
			padding: 30px 16px;

			@include breakpoint(medium-up) {
				padding: 30px;
			}


			// 			span {
			// 				border: 1px solid $primary-color;
			// 				padding: 10px 20px;
			// 				position: relative;
			// 
			// 				@include breakpoint(medium-up) {
			// 					padding: 10px 30px;
			// 				}
			// 
			// 
			// 				&::before,
			// 				&::after {
			// 					content: "";
			// 					display: block;
			// 					position: absolute;
			// 					width: calc(100% - 20px);
			// 					height: 3px;
			// 					left: 10px;
			// 					background: #f5f6fa;
			// 				}
			// 
			// 				&::before {
			// 					top: -2px;
			// 				}
			// 
			// 				&::after {
			// 					bottom: -2px;
			// 				}
			// 			}
		}
	}

	.philosophy--item {
		background: $white;
		padding: 30px 20px;

		@include breakpoint(medium-up) {
			padding: 40px;
		}

		@include breakpoint(large-up) {
			display: flex;
			align-items: center;
		}



		&:not(:last-child) {
			margin-bottom: 20px;
		}

		.number {
			display: flex;
			flex-direction: column;
			text-align: center;
			align-items: center;
			font-size: 26px;
			font-family: $body-font-family-serif;
			color: $primary-color;
			line-height: 1;
			margin-bottom: 30px;

			@include breakpoint(large-up) {
				margin-right: 40px;
				margin-bottom: 0;
			}




			&::after {
				content: "";
				width: 20px;
				height: 1px;
				background: $primary-color;
				margin-top: 4px;
			}
		}
	}

	.s-message {
		&--name {
			text-align: right;
		}
	}
}




body.page-template-privacy {
	.article--inner {
		>section {
			&:not(:last-child) {
				margin-bottom: 50px;
			}

			h2 {
				color: $primary-color;
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 20px;
			}
		}
	}
}