@use "global" as *;

body.home,
body.front-page {
	main {
		.article {}
	}

	.sectionTitle {
		text-align: center;
		margin-bottom: 50px;

		&--english {
			font-size: 20px;
			color: $primary-color;
			font-family: $body-font-family-serif;
			letter-spacing: .15em;
			font-weight: 500;

			@include breakpoint(medium-up) {
				font-size: 26px;
			}

			@include breakpoint(large-up) {
				font-size: 32px;
			}


		}

		h2 {
			font-size: 13px;
			color: $primary-color;
			font-family: $body-font-family-serif;

			@include breakpoint(medium-up) {
				font-size: 15px;
			}

		}
	}

	.sectionHeading {
		font-family: $body-font-family-serif;
		font-size: 18px;
		font-weight: 500;
		letter-spacing: .15em;

		@include breakpoint(medium-up) {
			font-size: 26px;
		}

	}

	.viewMore {
		display: flex;
		justify-content: center;
		margin-top: 30px;

		@include breakpoint(medium-up) {
			margin-top: 50px;
			font-size: 15px;
		}


		a {
			color: $primary-color;
			font-family: $body-font-family-serif;
			font-weight: 500;
			display: flex;
			align-items: center;
		}

		i {
			font-size: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $primary-color;
			border-radius: 50%;
			width: 46px;
			height: 46px;
			margin-right: 13px;

			@include breakpoint(medium-up) {
				width: 60px;
				height: 60px;
			}

		}
	}

	// section Welcome
	.s-welcome {
		width: 100%;
		height: 100vh;
		height: 100dvh;
		position: relative;

		&--text {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 40%;
			min-height: 190px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: 15px;
			font-family: $body-font-family-serif;
			color: $primary-color;
			line-height: 2;
			letter-spacing: .15em;
			padding-top: 70px;
			background: $white;
			z-index: 5;

			>div {
				text-align: center;

				@include breakpoint(large-up) {
					text-align: start;
				}


				span {
					display: block;
				}
			}

			@include breakpoint(medium-up) {
				font-size: 18px;
			}

			@include breakpoint(large-up) {
				height: 100%;
				width: 30%;
				writing-mode: vertical-rl;
				left: auto;
				right: 0;
				background: rgba($white, .9);
				font-size: 20px;
				color: $black;
			}



			&::after {
				content: "";
				width: 100%;
				height: 100px;
				background: linear-gradient(rgba($white, 1), rgba($white, 0));
				position: absolute;
				top: 100%;
				z-index: 10;

				@include breakpoint(large-up) {
					display: none;
				}

			}
		}
	}

	.hero {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 60%;

		@include breakpoint(large-up) {
			height: 100%;
		}


		&--slide {
			width: 100%;
			height: 100%;

			li {
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;

				&:nth-child(1) {
					background-image: url(../images/hero_19.jpg);
				}

				&:nth-child(2) {
					background-image: url(../images/hero_20.jpg);
				}

				&:nth-child(3) {
					background-image: url(../images/hero_21.jpg);
				}

				&:nth-child(4) {
					background-image: url(../images/hero_22.jpg);
				}
			}
		}
	}

	// section News
	.s-news {
		background: #fafafa;
		padding-block: 43px 50px;
		margin-bottom: 70px;

		@include breakpoint(medium-up) {
			margin-bottom: 200px;
		}


		&--inner {
			@include container;
			display: flex;
			max-width: 1060px;
		}

		.sectionTitle {
			text-align: left;
			margin-right: 20px;

			@include breakpoint(medium-up) {
				margin-right: 30px;
			}

			@include breakpoint(large-up) {
				margin-right: 100px;
			}


		}

		.news {
			padding-top: 7px;

			&--item {
				&:not(:last-child) {
					margin-bottom: 15px;

					@include breakpoint(medium-up) {
						margin-bottom: 20px;
					}
				}

				a {
					color: $black;

					@include hover {
						color: $secondary-color;
					}

					@include breakpoint(large-up) {
						display: flex
					}
				}

				.top {
					margin-bottom: 6px;
					flex-shrink: 0;

					@include breakpoint(large-up) {
						margin-right: 20px;
					}

				}

			}

			&--date {
				font-size: 13px;
				color: $black !important;
			}

			&--category {
				background: $primary-color;
				color: $white;
				font-size: 11px;
				line-height: 1;
				padding: 3px 10px 4px;
				align-self: center;
				margin-left: 13px;

				&.recruit {
					background: $secondary-color;
				}
			}

			&--title {
				line-height: 1.5;
				padding-top: 8px;
			}
		}
	}

	// section Philosophy
	.s-philosophy {
		margin-bottom: 100px;
		@include container;
		position: relative;

		@include breakpoint(medium-up) {
			margin-bottom: 200px;
		}


		&--text {
			line-height: 2;

			@include breakpoint(medium-up) {
				text-align: center;

				span {
					display: block;
				}
			}

		}

		.sectionHeading {
			text-align: center;
			font-size: 20px;
			margin-bottom: 30px;

			@include breakpoint(medium-up) {
				font-size: 26px;
				margin-bottom: 40px;
			}

		}

		.circle {
			position: absolute;
			top: 0;
			left: 0;
			height: 300px;

			&-yellow {
				fill: rgba($secondary-color, .07);
				height: 90%;
				top: -10%;
				left: -20%;

				@include breakpoint(medium-up) {
					left: -10%;
				}

			}

			&-blue {
				fill: rgba($primary-color, .03);
				display: none;
				height: 40%;
				top: 60%;
				left: 10%;

				@include breakpoint(medium-up) {
					display: block;
				}

			}
		}
	}

	//section Efforts
	.s-efforts,
	.s-pharmacy {
		margin-bottom: 100px;

		@include breakpoint(medium-up) {
			margin-bottom: 200px;
		}


		.sectionHeading {
			&::after {
				content: "";
				display: block;
				height: 1px;
				width: 60%;
				background: $primary-color;
				margin-block: 15px;
				transform: translateX(-20px);

				@include breakpoint(medium-up) {
					margin-block: 25px;
				}

			}
		}

		.pickup {
			&--item {
				&:not(:last-child) {
					margin-bottom: 70px;

					@include breakpoint(large-up) {
						margin-bottom: 100px;
					}

				}

				@include breakpoint(large-up) {
					display: flex;
					align-items: center;
					max-width: $global-width;
					margin-inline: auto;
				}

			}

			&--image {
				margin-bottom: 30px;

				@include breakpoint(large-up) {
					order: 2;
					width: 52%;
				}


				img {
					object-fit: cover;
					aspect-ratio: 16/9;

					@include breakpoint(large-up) {
						aspect-ratio: 3/2;
					}


				}
			}

			&--text {
				@include container;

				@include breakpoint(large-up) {
					order: 1;
					width: 48%;
				}

			}

		}

		.viewMore {
			justify-content: flex-start;
		}

	}

	.s-recruit {
		@include breakpoint(medium-up) {
			margin-bottom: 100px;
		}

		.recruit {
			background-image: url(../images/background-recruit.jpg);
			background-repeat: no-repeat;
			background-position: left top;
			background-size: 125%;
			width: 100%;
			aspect-ratio: 4/3;
			min-height: 330px;
			padding-block: 20px;
			position: relative;

			@include breakpoint(medium-up) {
				background-size: cover;
				background-position: left center;
				aspect-ratio: 5/2;
				padding-block: 40px;
				margin-bottom: 30px;
			}

			@include breakpoint(large-up) {
				max-height: 600px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				background-size: 125%;
			}

			&--inner {
				@include container;
			}

			&--catchphrase {
				margin-bottom: 15px;
				width: 50%;
				min-width: 260px;

				img {
					width: 100%;
				}

			}

			&--title {
				font-family: $body-font-family-serif;
				color: $white;
				letter-spacing: .15em;
				font-size: 16px;

				span {
					display: block;
				}

				@include breakpoint(medium-up) {
					font-size: 20px;
				}


				@include breakpoint(large-up) {
					font-size: 26px;

					span {
						display: inline;
					}
				}
			}

			&--list {
				@include breakpoint(medium-up) {
					@include container;
					display: flex;
					flex-wrap: wrap;
				}

			}

			&--item {
				@include breakpoint(small-only) {
					&:not(:last-child) {
						border-bottom: 1px solid $medium-gray;
					}
				}

				@include breakpoint(medium-up) {
					width: 32%;
					margin-right: 2%;
					margin-bottom: 20px;

					&:nth-child(3n) {
						margin-right: 0;
					}
				}


				a {
					min-width: auto;
					display: block;

					@include breakpoint(small-only) {
						border: none;
						text-align: left;
						padding-left: 20px;
						position: relative;

						&::after {
							font-family: FontAwesome;
							content: "\f105";
							color: $secondary-color;
							position: absolute;
							right: 15px;
						}
					}

					@include breakpoint(medium-up) {
						padding: 15px;
					}

					@include breakpoint(large-up) {
						padding: 20px;
					}



				}

			}

			.viewMore {
				position: absolute;
				left: 20px;
				bottom: 20px;

				@include breakpoint(medium-up) {
					left: 80px;
				}

				@include breakpoint(large-up) {
					position: static;
					justify-content: flex-start;
				}


			}
		}
	}
}