@use "global" as *;

.globalFooter {
	&--inner {
		@include breakpoint(large-up) {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}

	}

	&--top {
		order: 2;
		text-align: left;
	}

	&--bottom {
		@include breakpoint(large-up) {
			display: flex;
		}

		.copyright {
			order: 1;
			margin-right: 20px;
		}

		.footerNav {
			order: 2;

			a {
				@include hover {
					color: $secondary-color;
				}
			}
		}

	}

	&--address {
		font-size: 12px;

		@include breakpoint(large-up) {
			margin-bottom: 0 !important;
		}

	}

}

.toTop {
	position: fixed;
	right: 10px;
	bottom: 10px;
	z-index: 80;

	a {
		color: $primary-color;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba($primary-color, .1);
		backdrop-filter: blur(4px);

		@include breakpoint(medium-up) {
			width: 60px;
			height: 60px;
		}

		@include breakpoint(large-up) {
			width: 100px;
			height: 100px;
			font-size: 18px;
		}

	}





}


.globalFooter {
	background: #4C6072;

	@include breakpoint(large-up) {
		height: 260px;
	}


	&--inner {
		@include container;
		padding-block: 30px;
		text-align: center;

		@include breakpoint(medium-up) {
			padding: 60px;
		}
	}

	&--logo {
		margin-bottom: 3px;
	}

	&--logo-english {
		font-size: 10px;
		color: $white;
		margin-bottom: 10px;
	}

	&--address {
		color: $white;
		margin-bottom: 15px;
	}

	.footerNav {
		margin-bottom: 5px;

		&--list {
			display: flex;
			justify-content: center;
			font-size: 10px;
		}

		&--item {
			&:first-child {
				&::after {
					content: "|";
					margin-inline: 10px;
					color: $white;
				}
			}

			a {
				color: $white;
			}
		}
	}


	.copyright {
		color: $white;
		font-size: 11px;
	}
}