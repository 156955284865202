@use "global" as *;

body[class*="page-template-contact"],
body[class*="page-template-entry"] {

	.check-area {
		text-align: center;
	}

	span {
		&.required {
			background: $warning-color;
			color: $white;
			font-size: 11px;
			border-radius: 3px;
			padding: 0 3px 1px;
			margin-right: 6px;
		}
	}

	.box {
		h3 {
			font-size: 18px;
		}
	}


	ul.button-area {
		list-style: none;
		padding-left: 0;
		text-align: center;

		li {
			display: inline-block;
			margin: 10px;
		}
	}

	td.date {
		display: flex;

		>span {
			padding: 9px 6px 0;
		}
	}
}