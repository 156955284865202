@use "global" as *;

body.page-template-company {
	.table-information {
		width: 100%;

		tr {
			border-bottom: 1px dotted $primary-color;

			&:first-child {
				border-top: 1px dotted $primary-color;
			}
		}

		th,
		td {
			padding: 15px 10px;
			font-size: 13px;

			@include breakpoint(medium-up) {
				font-size: 14px;
				padding: 20px;
			}

		}

		th {
			font-weight: 500;
			text-align: center;
		}

	}

	.map {
		iframe {
			height: 290px;

			@include breakpoint(medium-up) {
				height: 390px;
			}

		}
	}

}