@use "global" as *;

body.page-template-efforts {

	.list-circle {
		color: $primary-color;
	}

	.efforts {
		&--list {
			margin-bottom: 40px;
		}

		&--item {
			&:not(:last-child) {
				margin-bottom: 20px;
			}

			background: $white;
			padding: 20px;
			color: $primary-color;

			@include breakpoint(medium-up) {
				display: flex;
				justify-content: space-between;
				padding: 40px;
			}

			.text {
				@include breakpoint(medium-up) {
					width: 70%;
					padding-right: 40px;
				}

			}

			figure {
				@include breakpoint(medium-up) {
					width: 30%;
					margin: 0;
				}

			}


			.name {
				border-top: 1px dotted $primary-color;
				padding-top: 10px;
				margin-top: 10px;
				margin-bottom: 20px;

				@include breakpoint(medium-up) {
					margin-bottom: 0;
				}

			}

			img {}
		}
	}
}