@use "global" as *;

body.page-template-interview01 {
	.mainVisual {
		background-image: url(../images/recruit/abe/image01.jpg) !important;
	}
}

body.page-template-interview02 {
	.mainVisual {
		background-image: url(../images/recruit/ueda/image01.jpg) !important;
	}
}

body.page-template-interview03 {
	.mainVisual {
		background-image: url(../images/recruit/izumi/image01.jpg) !important;
	}
}

body.page-template-interview04 {
	.mainVisual {
		background-image: url(../images/recruit/nakanishi/image01.jpg) !important;
	}
}

body.page-template-interview05 {
	.mainVisual {
		background-image: url(../images/recruit/fukuda/image01.jpg) !important;
	}
}

body.page-template-interview01,
body.page-template-interview02,
body.page-template-interview03,
body.page-template-interview04,
body.page-template-interview05 {

	.mainVisual {
		background-position: center center !important;
		background-size: cover !important;

		&--inner {
			@include container;
			max-width: $global-width-narrow;
			position: relative;
			height: 100%;

			.number {
				position: absolute;
				bottom: -38px;
				right: 20px;
				font-size: 56px;
				color: $secondary-color;
				font-family: $body-font-family-serif;
				line-height: 1;

				@include breakpoint(medium-up) {
					font-size: 80px;
					bottom: -40px;
				}


				&::before {
					content: "#";
					font-size: 18px;

					@include breakpoint(medium-up) {
						font-size: 27px;
					}

				}
			}
		}
	}

	.articleHeader {
		border-bottom: 1px dotted $primary-color;
		padding-bottom: 50px;
		margin-bottom: 50px;

		@include breakpoint(medium-up) {
			margin-bottom: 100px;
		}


		&--inner {
			position: relative;

			@include breakpoint(large-up) {
				display: flex;
				justify-content: space-between;
				margin-top: 3px;
			}
		}

		&--title {
			font-family: $body-font-family-serif;
			font-size: 20px;
			color: $primary-color;
			letter-spacing: .15em;
			font-weight: 500;
			margin-bottom: 30px;

			@include breakpoint(medium-up) {
				font-size: 26px;
				margin-bottom: 0;
			}
		}

		&--profile {
			text-align: right;

		}

		&--position {
			background: $secondary-color;
			padding: 5px 20px;
			color: $black;
		}


		&--name {
			font-family: $body-font-family-serif;
			font-size: 22px;
			line-height: 1.6;
			display: block;
			margin-block: 10px;


			&-english {
				display: block;
				font-size: 10px;

			}
		}

		&--year {
			font-size: 12px;
			line-height: 1.5;
			display: block;
		}

	}

	.sectionHeading {
		position: relative;
		border-bottom: 1px solid $medium-gray;
		padding-bottom: 20px;

		&::after {
			content: "";
			display: block;
			width: 30%;
			height: 1px;
			position: absolute;
			background: $primary-color;
			bottom: -1px;
		}

		@include breakpoint(medium-up) {
			font-size: 20px !important;
		}

	}



}