@use "global" as *;

input,
select,
textarea {
	// Mac/iOS Safariでplaceholderが上にずれるのを防ぐ。
	line-height: 1.6;
	border-radius: 4px;
	border: 1px solid #e2e8f0;
	background: $white;
}

input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"] {
	padding: .5rem .8rem;
	width: 14em;
}

textarea {
	padding: .5rem .8rem;
	width: 100%;
	min-height: 4lh;
	form-sizing: content;
}

input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
textarea {
	box-shadow: 0 1px 3px 0 rgba($black, .1), 0 1px 2px 0 rgba($black, .06);
	outline: 2px solid transparent;
	outline-offset: 2px;
}


// focus
input[type="text"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus {
	box-shadow: 0 0 0 3px rgba($primary-color, .5);
	outline: 2px solid transparent;
	outline-offset: 2px;
}

// error
input[type="text"].is-error,
input[type="number"].is-error,
input[type="tel"].is-error,
input[type="email"].is-error,
input[type="password"].is-error,
select.is-error,
textarea.is-error {
	border: 1px solid $warning-color;
	background: $white;
}

select {
	//オリジナルの矢印画像を表示するためpadding-rightを広くあけておく。
	padding: 1rem 3.6rem 1rem 1.2rem;
	background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2225.532%22%20height%3D%2214%22%20viewBox%3D%220%200%2025.532%2014%22%3E%3Cpath%20d%3D%22M82.249%2C82.431l.875.851%2C12.25-11.915h0l.875-.851-.875-.851h0L83.124%2C57.75l-.875.851L94.5%2C70.516Z%22%20transform%3D%22translate(83.282%20-82.249)%20rotate(90)%22%20fill%3D%22%23999%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat;
	background-position: calc(100% - 8px) center;
	background-size: 14px 8px;
}

// ブラウザデフォルト表示のクリア【2】。IE11のselect矢印を非表示にする。
select::-ms-expand {
	display: none;
}

// ブラウザデフォルト表示のクリア【3】。IE11/Edgeの☓印クリアボタンを非表示にする。
input::-ms-clear {
	visibility: hidden;
}

//IE11のplaceholderテキスト色を設定する。
:-ms-input-placeholder {
	color: #9EA3A5;
}

//その他のブラウザのplaceholderテキスト色を設定する。
::-webkit-input-placeholder {
	color: $medium-gray;
}

input[type="checkbox"],
input[type="radio"] {
	//checkboxとradioを非表示にする。
	// display: none;
}

label {
	cursor: pointer;
}

button[type="submit"] {
	background: $primary-color;
	color: $white;
	padding: .5em .8em;
	line-height: 1.6;
	border-radius: 4px;

	@include hover {
		background: darken($primary-color, 7%);
		transition: $global-anchor-transition;
	}
}

.c-checkbox {
	//checkboxの後ろにlabelまたはspanなどを配置する。
	display: inline-flex;
	align-items: center;
	text-align: left;
	line-height: 1.2;
	cursor: pointer;

	&:before {
		content: "";
		display: block;
		margin-right: .6rem;
		border: 1px solid $medium-gray;
		border-radius: 3px;
		background: $white;
		width: 22px;
		height: 22px;
		// IE11でbeforeコンテンツ幅を保持する。
		flex: 1 0 auto;
	}
}

:checked+.c-checkbox {
	position: relative;

	&:before {
		border: 1px solid #008EC1;
		background: #008EC1;
	}

	&:after {
		content: "";
		display: inline-block;
		width: 17px;
		height: 17px;
		background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20enable-background%3D%22new%200%200%2024%2016%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20clip-rule%3D%22evenodd%22%20d%3D%22m24%202.8-14.1%2013.2-9.9-9.3%202.6-2.8%207.2%206.8%2011.5-10.7z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		left: 3px;
		top: 0;
		bottom: 0;
		margin: auto;
	}
}

.c-radio {
	// radioの後ろにlabelまたはspanなどを配置する。
	display: inline-flex;
	align-items: center;
	text-align: left;
	line-height: 1.2;
	cursor: pointer;

	&:before {
		content: "";
		display: block;
		margin-right: .6rem;
		border: 1px solid #CED1D2;
		border-radius: 50%;
		background: #F9FAFA;
		width: 22px;
		height: 22px;
		// IE11でbeforeコンテンツ幅を保持する。
		flex: 1 0 auto;
	}
}

:checked+.c-radio {
	position: relative;

	&:after {
		content: "";
		display: block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #008EC1;
		position: absolute;
		left: 7px;
		top: 0;
		bottom: 0;
		margin: auto;
	}
}


.form-area {
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 70px;

	tr {
		@include breakpoint(medium-up) {
			border-bottom: 1px dotted rgba($primary-color, .3);

		}

	}

	th,
	td {
		text-align: left;
		padding: 20px;

	}

	th {
		width: 200px;
		vertical-align: text-top;
		font-weight: normal;
		font-size: 13px;
		color: $black;
		margin-bottom: 5px;
		padding-top: 30px;

		span {
			background: $warning-color;
			color: $white;
			font-size: 11px;
			border-radius: 3px;
			padding: 0 3px 1px;
			margin-right: 6px;

			&.optional {
				background: $medium-gray;
			}
		}
	}

	td {
		width: calc(100% - 200px);
		max-width: 480px;
		margin-bottom: 25px;

		&.number {
			font-weight: bold;
			font-size: 18px;
			padding-top: 12px;
		}

		&.date {
			select {
				text-align: right;
				width: 10em;
			}
		}

		&.graduate {
			width: auto;
		}

		&.zip {
			input {
				width: 10em;
			}
		}

		&.address {
			input {
				width: 100%;
				max-width: 30em;
			}
		}
	}

	@include breakpoint(small-only) {
		margin-bottom: 40px;

		th,
		td {
			display: block;
			border-bottom: none;
			padding: 0;
		}

		td {
			margin-bottom: 20px;

			&.date {
				select {
					width: 6em;
				}
			}
		}
	}
}