@use "global" as *;

body.page-template-recruit,
body.page-template-interview01,
body.page-template-interview02,
body.page-template-interview03,
body.page-template-interview04,
body.page-template-interview05,
body.page-template-entry,
body.page-template-entry-confirm,
body.page-template-entry-error,
body.page-template-entry-complete,

{


main {
	.article {
		background: none;
	}
}

.pageHeader {

	&.small {
		padding: 0;
	}

}

.mainVisual {
	background-image: url(../images/background-recruit.jpg);
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 125%;
	width: 100%;
	aspect-ratio: 2/1;
	min-height: 280px;
	padding-block: 20px;
	position: relative;

	&--catchphrase {
		position: absolute;
		bottom: 30px;
		left: 20px;
		width: 50%;
		min-width: 260px;

		img {
			width: 100%;
		}

	}
}


.slide-interview {
	overflow-x: hidden;
	margin-inline: -20px;
	padding-block: 30px;

	@include breakpoint(medium-up) {
		margin-inline: 0;
		margin-right: -80px;
		padding-bottom: 50px;
	}

	.swiper-slide {
		a {
			position: relative;
		}
	}
}

.staff {

	&--number,
	&--position,
	&--staff {
		position: absolute;
	}

	&--number {
		top: -35px;
		left: 0;
		font-size: 32px;
		color: $secondary-color;
		font-family: $body-font-family-serif;

		@include breakpoint(medium-up) {
			font-size: 41px;
			top: -40px;
		}


		&::before {
			content: "#";
			font-size: 18px;

			@include breakpoint(medium-up) {
				font-size: 27px;
			}

		}
	}

	&--position {
		background: rgba($secondary-color, .9);
		left: 0;
		bottom: 0;
		padding: 3px 20px;
		color: $black;
		font-size: 12px;
	}

	&--bottom {
		display: flex;
		justify-content: space-between;
		margin-top: 3px;
	}

	&--name {
		font-family: $body-font-family-serif;
		font-size: 18px;
		line-height: 1.6;

		@include breakpoint(medium-up) {
			font-size: 22px;
		}


		&-english {
			display: block;
			font-size: 10px;

		}
	}

	&--year {
		font-size: 12px;
	}
}

.swiper-pagination-bullet-active {
	background: $secondary-color;
}

.swiper-button-next,
.swiper-button-prev {
	color: $secondary-color;

	&::after {
		font-size: 28px;
	}
}


.figureBlock {
	text-align: center;

	@include breakpoint(medium-up) {
		margin-block: 70px;

	}


	figcaption {
		border-bottom: 1px dotted $medium-gray;
		margin-bottom: 15px;
		display: inline-block;

		@include breakpoint(medium-up) {
			font-weight: bold;
			margin-bottom: 40px;
			font-size: 16px;
		}

	}

	&.schedule {
		figure {
			margin-top: 0;
			margin-right: -20px;
			padding-bottom: 10px;
			overflow-x: scroll;

			@include breakpoint(medium-up) {
				margin-right: -80px;
			}


			img {
				width: 788px;
				max-width: 788px;

				@include breakpoint(medium-up) {
					width: 1200px;
					max-width: 1200px;
				}

			}
		}
	}
}

.figureGroup {
	figure {
		&:first-child {
			@include breakpoint(medium-down) {
				margin-top: 0;

			}
		}

		@include breakpoint(medium-up) {
			margin-top: 30px;
		}

	}

	@include breakpoint(medium-up) {
		display: flex;
	}

}

.member {
	display: flex;
	justify-content: space-between;
	margin-block: 50px;

	@include breakpoint(medium-up) {
		justify-content: space-around;
	}


	&--item {
		width: 49%;
		text-align: center;

		@include breakpoint(medium-up) {
			width: 40%;
		}

		;

		img {
			margin-bottom: 15px;
		}
	}

	&--position {
		font-size: 13px;
		display: block;
	}

	&--name {
		font-size: 16px;
		color: $primary-color;
		display: block;
	}
}

.s-follow {
	margin-top: 70px;
	margin-bottom: 70px;

	@include breakpoint(medium-up) {
		margin-top: 140px;
		margin-bottom: 140px;
	}


	&--box {
		background: rgba($primary-color, .05);
		padding: 30px 20px;
		margin-bottom: 20px;

		@include breakpoint(medium-up) {
			padding: 40px;
		}




		h5 {
			color: $primary-color;
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 20px;
			text-align: center;

			@include breakpoint(medium-up) {
				text-align: left;
			}


			span {
				border-bottom: 1px dotted;
				padding-bottom: 10px;
			}
		}
	}

	.career-pass {
		margin-top: 50px;

		@include breakpoint(medium-up) {
			margin-top: 70px;
		}


	}
}


.s-system {
	.system {
		@include breakpoint(medium-up) {
			column-count: 2;
		}

		&--item {
			background: rgba($primary-color, .05);
			padding: 30px 20px;
			margin-bottom: 20px;
			break-inside: avoid;

			h4 {
				color: $primary-color;
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 20px;
				text-align: center;

				span {
					border-bottom: 1px dotted;
					padding-bottom: 10px;
				}
			}

		}
	}
}


.s-guideline {}


.buttons {
	max-width: 300px;
	margin-inline: auto;

	@include breakpoint(medium-up) {
		display: flex;
		justify-content: center;
	}

	&--item {
		&:not(:last-child) {
			@include breakpoint(medium-up) {
				margin-right: 15px;
			}

		}
	}

	.button {
		display: flex;
		padding: 0;
		height: 60px;
		justify-content: center;
		align-items: center;
		margin-bottom: 15px;


		img {
			height: 25px;
		}
	}

}
}



body.page-template-recruit {}