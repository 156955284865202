@use "variables" as *;

// media queries
@mixin breakpoint($size) {
	@if $size ==small-only {
		@media (max-width: 767px) {
			@content;
		}
	}

	@else if $size ==medium-up {
		@media (min-width: 768px) {
			@content;
		}
	}

	@else if $size ==large-up {
		@media (min-width: 960px) {
			@content;
		}
	}

	@else if $size ==xlarge-up {
		@media (min-width: 1200px) {
			@content;
		}
	}

	@else if $size ==xxlarge-up {
		@media (min-width: 1440px) {
			@content;
		}
	}

	@else if $size ==medium-down {
		@media (max-width: 959px) {
			@content;
		}
	}
}

// hover
@mixin hover {
	@media (hover: hover) {
		&:hover {
			@content;
		}
	}
}

// container
@mixin container {
	width: 100%;
	max-width: $global-width;
	margin-inline: auto;
	padding-inline: $global-container-padding-small;

	@include breakpoint(medium-up) {
		padding-inline: $global-container-padding-large;
	}
}

.container {
	@include container;
}